import { thunk } from 'easy-peasy';
import { syncProjectsAPICall } from '../../api/projects';

const thunks = {
  syncProjectsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      await syncProjectsAPICall();
    } catch (err) {
      console.log('failed');
      actions.setAlertThunk({
        type: 'error',
        message: 'Failed to sync projects',
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
