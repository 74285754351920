import { axios } from '.';

const projectAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_ADMIN_JWT}`,
  },
});

async function syncProjectsAPICall() {
  return projectAxios({
    method: 'post',
    url: `/api/v1/project`,
  });
}

export { projectAxios, syncProjectsAPICall };
